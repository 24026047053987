<template>
  <div>
    <van-nav-bar
      title="问询专家"
      left-text="返回"
      left-arrow
      @click-left="bank()"
    />
    <van-field
      readonly
      clickable
      name="crops"
      :value="crops"
      label="请选择作物"
      placeholder="请选择"
      @click="showCrops = true"
    />
    <van-popup v-model="showCrops" position="bottom">
      <van-picker
        show-toolbar
        :columns="cropsData"
        value-key="crop_name"
        @confirm="onConfirm"
        @cancel="showCrops = false"
      />
    </van-popup>
    <van-cell-group>
      <van-field
        v-model="trouble"
        rows="10"
        autosize
        label="留言"
        type="textarea"
        maxlength="50"
        placeholder="请输入留言"
        show-word-limit
      />
    </van-cell-group>
    <van-uploader
      v-model="fileList"
      accept=".jpg, .jpeg, .png, .bmp"
      :after-read="afterRead"
      max-count="5"
      ref="dd"
      multiple
    />
    (最多上传5张照片))
    <div style="width:90%; margin: 10px auto">
      <van-button round block type="info" native-type="submit" style="background:#001f90" @click="seve()"
        >提交</van-button
      >
    </div>
    <van-overlay :show="show">
      <div class="wrapper" @click.stop>
        <div class="block">
          <van-loading
            size="24px"
            color="#0094ff"
            text-color="#0094ff"
            style="text-align: center; margin-bottom: 50%;"
            >上传中...</van-loading
          >
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getExpertList, getExpertUpload } from "@/service/expert.js";
import {
  NavBar,
  CellGroup,
  Field,
  Popup,
  Picker,
  Uploader,
  Button,
  Overlay,
  Loading
} from "vant";
export default {
  name: "expert",
  data() {
    return {
      trouble: "",
      crops: "",
      crops_id: "",
      cropsData: [],
      fileList: [],
      img: [],
      showCrops: false,
      show: false
    };
  },
  components: {
    "van-field": Field,
    "van-popup": Popup,
    "van-picker": Picker,
    "van-cell-group": CellGroup,
    VanUploader: Uploader,
    VanButton: Button,
    VanNavBar: NavBar,
    VanOverlay: Overlay,
    VanLoading: Loading
  },
  mounted() {
    this.getCropExpert();
  },
  methods: {
    bank() {
      this.$router.go(-1);
    },
    afterRead(file) {
    },
    onConfirm(crops) {
      this.crops = crops.crop_name;
      this.crops_id = crops.crop_id;
      this.showCrops = false;
    },
    getCropExpert() {
      getExpertList().then(res => {
        const data = res.data;
        this.cropsData = data;
      });
    },
    seve() {
      this.show = true;
      const param = new FormData();
      for (var i = 0; i < this.fileList.length; i++) {
        param.append("files", this.fileList[i].file);
      }
      param.append("crop_id", this.crops_id);
      param.append("content", this.trouble);
      getExpertUpload(param).then(res => {
        if (res.message === "成功") {
          this.$router.push("/message");
        } else if (res.data === "失败") {
          this.show = false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
// .trouble {
//   width: 100%;
//   min-height: 500px;
//   background: red;
// }
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .block {
    width: 120px;
    height: 120px;
  }
}
</style>
