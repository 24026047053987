import server from "./axios.config.js";

export const getExpertList = function () {
  return server({
    method: "get",
    url: 'http://110.87.103.59:18086/farmfriend/crop/getAllCrop'
  })
}

export const getExpertUpload = function (param, config) {
  return server({
    method: "post",
    url: 'http://110.87.103.59:18086/farmfriend/info/submit',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: param
  })
}
